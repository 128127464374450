import { Injectable, OnDestroy } from '@angular/core'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router'
import { Location } from '@angular/common'
import { combineLatest, Subject } from 'rxjs'
import { isEqual, omit, pick } from 'lodash'
import { distinctUntilChanged, filter, takeUntil } from 'rxjs/operators'
import { FuelGroupFilter } from '../models/fleet-vehicle.models'
import { EventsService } from './events.service'
import { FinalOutcomeKey } from '@fleet-customer/shared/fill-repartition-and-fueling-success'
import { isValid, isWithinInterval, parse } from 'date-fns'
import { BUI_TAB_QUERY_PARAM_KEY, BuiTab } from '@fleet-customer/booster-ui'

export type QueryParams = {
  selectedFleetAccountId?: string
  selectedFleetCompanyId?: string
  fuelGroup?: FuelGroupFilter
  subAccountId?: string // TODO IM refactor for multiselect or remove
  quarter?: 1 | 2 | 3 | 4
  year?: number
  acc?: string
  inv?: string
  tab?: BuiTab['value']
  finalOutcome?: FinalOutcomeKey
}

const VALID_QUERY_PARAMS = [
  'selectedFleetCompanyId',
  'selectedFleetAccountId',
  'fuelGroup',
  'subAccountId',
  'quarter',
  'year',
  BUI_TAB_QUERY_PARAM_KEY,
  'finalOutcome',
]

@Injectable({ providedIn: 'root' })
export class QueryParamsService implements OnDestroy {
  private readonly _destroy = new Subject<void>()
  private queryParams: QueryParams = {}

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private eventsService: EventsService
  ) {
    this.activatedRoute.queryParams
      .pipe(takeUntil(this._destroy), distinctUntilChanged(isEqual))
      .subscribe((queryParams) => {
        this.queryParams = {
          ...this.queryParams,
          ...this._normalize(queryParams),
        }
      })

    // preserve selectedFleetAccountId and selectedFleetCompanyId on route change
    combineLatest([
      this.activatedRoute.queryParams,
      this.router.events.pipe(filter((e) => e instanceof NavigationEnd)),
    ])
      .pipe(takeUntil(this._destroy))
      .subscribe(() => {
        this.setServiceLocationQueryParams(
          this.queryParams?.selectedFleetAccountId,
          this.queryParams?.selectedFleetCompanyId
        )
      })

    /*
    if leaving /dashboard or /services, remove all query params
    and set selectedFleetAccountId and selectedFleetCompanyId
    */
    this.eventsService.navigationStart$
      .pipe(takeUntil(this._destroy))
      .subscribe((event) => {
        const { url: nextUrl, originUrl: currentUrl } = event

        const isLeavingDashboard =
          currentUrl.startsWith('/dashboard') &&
          !nextUrl.startsWith('/dashboard')

        const isLeavingServices =
          currentUrl.startsWith('/services') && !nextUrl.startsWith('/services')

        const isLeavingServiceDetails =
          currentUrl.startsWith('/services') &&
          currentUrl.includes('details') &&
          !nextUrl.includes('details')

        if (isLeavingDashboard || isLeavingServices) {
          const selectedFleetAccountId =
            this.queryParams?.selectedFleetAccountId
          const selectedFleetCompanyId =
            this.queryParams?.selectedFleetCompanyId

          this.reset()
          this.setServiceLocationQueryParams(
            selectedFleetAccountId,
            selectedFleetCompanyId
          )
        } else if (isLeavingServiceDetails) {
          this.remove('finalOutcome')
        }
      })
  }

  ngOnDestroy(): void {
    this._destroy.next()
    this._destroy.complete()
  }

  public append(queryParams: QueryParams): void {
    this._set(
      this._normalize({
        ...this.queryParams,
        ...queryParams,
      })
    )
  }

  public override(queryParams: QueryParams): void {
    this._set(this._normalize(queryParams))
  }

  public reset(): void {
    this.override({})
  }

  public get(): QueryParams {
    return { ...this.queryParams }
  }

  public remove(params: keyof QueryParams | (keyof QueryParams)[]): void {
    this.override(omit(this.queryParams, params))
  }

  public setServiceLocationQueryParams(
    selectedFleetAccountId: string,
    selectedFleetCompanyId: string
  ): void {
    // remove, then append because falsy values don't override correctly
    this.remove(['selectedFleetAccountId', 'selectedFleetCompanyId'])
    this.append({
      selectedFleetAccountId,
      selectedFleetCompanyId,
    })
  }

  private _normalize(queryParams: any): QueryParams {
    queryParams = pick(queryParams, VALID_QUERY_PARAMS)
    Object.keys(queryParams).forEach((key) => {
      if (key === 'year') {
        const date = parse(queryParams[key] + '', 'yyyy', new Date())
        if (
          !isValid(date) ||
          !isWithinInterval(date, {
            start: new Date(2017, 0, 1),
            end: new Date(),
          })
        ) {
          queryParams[key] = 'ALL'
        } else {
          queryParams[key] = +queryParams[key]
        }
      }
      if (key === 'quarter') {
        if (![1, 2, 3, 4].includes(+queryParams[key])) {
          delete queryParams[key]
        } else {
          queryParams[key] = +queryParams[key]
        }
      }

      if (queryParams[key] === 'ALL') {
        delete queryParams[key]
      }
    })
    return queryParams
  }

  private _set(queryParams: QueryParams): void {
    this.queryParams = queryParams
    const urlTree = this.router.createUrlTree([], {
      relativeTo: this.activatedRoute,
      queryParams,
    })
    this.location.replaceState(urlTree.toString())
  }
}
